<template>
  <div>
    <div class="z-0">
      <header>
        <ConnectWallet />
      </header>

      <div class="mx-auto sm:max-w-xl md:max-w-full xl:max-w-screen-2xl p-2">
        <div class="mt-44">
          <h3 class="text-3xl font-sans font-bold">Trending Designs!</h3>
          <div
            class="grid gap-4 grid-cols-1 lg:grid-cols-4 md:grid-cols-2 mt-10"
          >
            <div>
              <img
                class="w-full"
                src="https://cdn-image02.casetify.com/usr/14315/17384315/~v1/28644892_iphone-14-pro-max_16004759.png.560x560-w.jpg"
              />
              <div class="text-center">
                <h3 class="text-xl pt-5">PLZ DO NOT DISTURB ME</h3>
              </div>
            </div>

            <div>
              <img
                class="w-full"
                src="https://cdn-stamplib.casetify.com/cms/image/7dc28554fefccff1667d13dcbd608f6d.png"
              />
              <div class="text-center">
                <h3 class="text-xl pt-5">
                  Metal Heart Cross-body Strap - Glossy Silver
                </h3>
              </div>
            </div>

            <div>
              <img
                class="w-full"
                src="https://cdn-image02.casetify.com/usr/21433/34611433/~v1/28862401_iphone-14-pro-max_16004815.png.560x560-w.jpg"
              />
              <div class="text-center">
                <h3 class="text-xl pt-5">STOP LOOKING By Shuturp</h3>
              </div>
            </div>

            <div>
              <img
                class="w-full"
                src="https://cdn-stamplib.casetify.com/cms/image/53d1e8a43740be105fb1fd6ec78a4ea4.png"
              />
              <div class="text-center">
                <h3 class="text-xl pt-5">
                  iPhone 14 Pro & 14 Pro Max Camera Lens <br />
                  Protector
                </h3>
              </div>
            </div>
          </div>
          <hr class="mt-10 mb-10" />
        </div>

        <div class="mt-44">
          <h3 class="text-3xl font-sans font-bold">#CraftMastersCommunity</h3>
          <div
            class="grid gap-4 grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-10"
          >
            <div class="text-center">
              <img
                class="w-full"
                src="https://cdn.discordapp.com/attachments/1080058777971212370/1126734786677968906/POD_Banner_Community_02.png"
              />
              <h3 class="text-lg font-bold pt-5">
                LIFESTYLE INFLUENCER: @iva.carob X Frosted Sunset Case
              </h3>
            </div>

            <div class="text-center">
              <img
                class="w-full"
                src="https://cdn.discordapp.com/attachments/1080058777971212370/1126734766436253726/POD_Banner_Community_01.png"
              />
              <h3 class="text-lg font-bold pt-5">
                FASHION MODEL: @xxdiyar X Be A Nice Human Case
              </h3>
            </div>

            <div class="text-center">
              <img
                class="w-full"
                src="https://cdn.discordapp.com/attachments/1080058777971212370/1126734821784289290/POD_Banner_Community_03.png"
              />
              <h3 class="text-lg font-bold pt-5">
                ACTRESS: @sammy_3008 X Self Love Stickers Case
              </h3>
            </div>
          </div>
          <hr class="mt-16 mb-16" />
        </div>

        <div class="mt-40">
          <h3 class="text-3xl font-sans font-bold">CraftMasters Artist</h3>
          <div
            class="grid gap-4 grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-10"
          >
            <div class="text-center">
              <img
                class="w-full"
                src="https://cdn.discordapp.com/attachments/1080058777971212370/1126462977365790832/POD_Banner_Artist_01.png"
              />
              <h3 class="text-lg font-bold pt-5">SSEBONG</h3>
            </div>

            <div class="text-center">
              <img
                class="w-full"
                src="https://cdn.discordapp.com/attachments/1080058777971212370/1126462977718104155/POD_Banner_Artist_02.png"
              />
              <h3 class="text-lg font-bold pt-5">YOUNG FOREST</h3>
            </div>

            <div class="text-center">
              <img
                class="w-full"
                src="https://cdn.discordapp.com/attachments/1080058777971212370/1126462978099793970/POD_Banner_Artist_03.png"
              />
              <h3 class="text-lg font-bold pt-5">Esther Bunny</h3>
            </div>
          </div>
          <hr class="mt-10 mb-16" />
        </div>

        <div class="mt-24">
          <h3 class="text-3xl font-sans font-bold">CraftMasters Artist</h3>

          <img
            class="w-full mt-5"
            src="https://cdn.discordapp.com/attachments/1080058777971212370/1126449730751299704/POD_Banner_Our_Mission.png"
          />
          <div class="text-center">
            <h3 class="text-lg font-bold pt-2">
              Re/CLAIM Re/IMAGINE Re/CraftMasters
            </h3>
            <h3 class="text-5xl font-sans font-bold pt-2 text-green-600">
              Re/CraftMasters
            </h3>

            <div
              class="inline-block mt-5 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded-2xl"
            >
              <div
                class="inline-block text-base px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl text-gray-200 bg-gray-900 cursor-pointer hover:bg-gray-200 hover:text-gray-900"
              >
                Learn More
              </div>
            </div>
            <hr class="mt-16 mb-16" />
          </div>
        </div>

        <div class="mt-44">
          <h3 class="text-3xl font-sans font-bold">The Essentials</h3>
          <div
            class="grid gap-4 grid-cols-1 lg:grid-cols-4 md:grid-cols-2 mt-10"
          >
            <div>
              <img
                class="w-full"
                src="https://cdn-image02.casetify.com/usr/4117/23884117/22442651_custom-phone-strap_1240001.png.560x560-w.jpg"
              />
              <div class="text-center">
                <h3 class="text-lg pt-5">Custom Phone Charm</h3>
              </div>
            </div>

            <div>
              <img
                class="w-full"
                src="https://cdn-stamplib.casetify.com/cms/image/53ebda6307737bff39f48b1d5ebab50d.png"
              />
              <div class="text-center">
                <h3 class="text-lg pt-5">Rope Cross-body Strap - Black</h3>
              </div>
            </div>

            <div>
              <img
                class="w-full"
                src="https://cdn-stamplib.casetify.com/cms/image/6126155cc5b7a444a0e83e4dfd85c925.png"
              />
              <div class="text-center">
                <h3 class="text-lg pt-5">
                  Anti Blue Light Tempered Glass with Anti Microbial function
                </h3>
              </div>
            </div>

            <div>
              <img
                class="w-full"
                src="https://cdn-image02.casetify.com/usr/25496/1195496/26056367_laptop-sleeve-large_16004824.png.560x560-w.jpg"
              />
              <div class="text-center">
                <h3 class="text-lg pt-5">boho hipple sticker</h3>
              </div>
            </div>
          </div>
          <hr class="mt-10 mb-10" />
        </div>

        <div class="mt-44">
          <h3 class="text-3xl font-sans font-bold">#CraftMastersCares</h3>
          <div
            class="grid gap-4 grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-10"
          >
            <div>
              <img
                class="w-full"
                src="https://cdn-stamplib.casetify.com/cms/image/b9b0129a0aca61d2b92b5ddeef41f3e8.jpg.webp"
              />
              <div class="">
                <h3 class="text-lg pt-5">It's Okay To Put Yourself First</h3>
                <h3 class="text-md font-serif text-gray-700 pt-3">
                  During Mental Health Awareness Month, we're donating $5 from
                  every sale to To Write Love On Her Arms, a community dedicated
                  to preserving hope and finding help for people struggling with
                  depression, addiction, self-injury, and suicide.
                </h3>
              </div>
            </div>

            <div>
              <img
                class="w-full"
                src="https://cdn-stamplib.casetify.com/cms/image/e2c50ed35659ba1e35d9076ae96b5ff2.jpg.webp"
              />
              <div class="">
                <h3 class="text-lg pt-5">Be Breast Aware</h3>
                <h3 class="text-md font-serif text-gray-700 pt-3">
                  A collection to raise breast cancer awareness. CraftMasters
                  will donate US$5 for each case sold from the collection to
                  Living Beyond Breast Cancer during October.
                </h3>
              </div>
            </div>

            <div>
              <img
                class="w-full"
                src="https://cdn-stamplib.casetify.com/cms/image/fbd2847eab3cfceab2cf2d774ffe5778.jpg.webp"
              />
              <div class="">
                <h3 class="text-lg pt-5">Pawfect Love</h3>
                <h3 class="text-md font-serif text-gray-700 pt-3">
                  Show your love for furry friends with this cuddly collection
                  that cares! During the month of August, CraftMasters will
                  donate $5 for every case sold to the IFAW.
                </h3>
              </div>
            </div>
          </div>
          <hr class="mt-10 mb-10" />
        </div>
        <div class="mt-24">
          <h3 class="text-3xl font-sans font-bold">Join Us</h3>

          <img
            class="w-full mt-5"
            src="https://cdn.discordapp.com/attachments/1080058777971212370/1126454141745643571/POD_Banner_Join_Us.png"
          />
          <div class="text-center">
            <h3 class="text-sm font-bold pt-2">
              Get exclusive rewards and privileges
            </h3>
            <h3 class="text-5xl font-sans font-bold pt-2 text-blue-600">
              CraftMasters Club
            </h3>

            <div
              class="inline-block mt-5 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded-2xl"
            >
              <div
                class="inline-block text-base px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl text-gray-200 bg-gray-900 cursor-pointer hover:bg-gray-200 hover:text-gray-900"
              >
                Learn More
              </div>
            </div>
          </div>
        </div>
      </div>

      <StickFooter class="mt-44" />
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/Footer.vue";

import { mapGetters, mapActions } from "vuex";

import { POOL_ADDRESS, VERSION } from "../../config";

import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";

// import { products, main_product, products_2 } from "../../products";

export default {
  data() {
    return {};
  },
  components: {
    ConnectWallet,
    StickFooter,
    Carousel,
    Navigation,
    Pagination,
    Slide,
  },
  computed: mapGetters(["getMenu"]),

  methods: {},
  created() {},
};
</script>
